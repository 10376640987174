/* .container {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  height: 55rem;
  width: 75rem;
  display: flex;
} */

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
    width: 100%;
    height: 100%;
}
.title {
    font-family: "yeongdeok-sea";
}

.container {
    display: flex;
    flex-direction: column;
}

.container__section {
    display: flex;
    flex-direction: row;
    border: 2px solid var(--color-accent);
    padding: 1rem 10px;
    width: 50rem;
    height: 20rem;
}

.run_button {
    background-color: var(--color-primary);
    font-size: 1.2rem;
    border-radius: 0.5rem;
}
.run_button:hover {
    background-color: var(--color-accent);
}

@media screen and (max-width: 768px) {
    .home {
        width: 550px;
    }
    .container__section {
        padding: 0;
        width: 500px;
        height: 20rem;
    }
}
