.bodySection {
    height: 30rem;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.navGroup {
    display: flex;
    gap: 5px;
}

.navGroupItem {
    padding: 0.75rem 1.25rem;

    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
}

.navGroupItemAction {
    width: 100%;
    color: #495057;
    text-align: center;
}

.navGroupItemAction:hover {
    color: #495057;
    background-color: #f8f9fa;
}

.navGroupItemAction.active {
    color: #fff;
    background-color: var(--color-primary);
    border-color: var(--color-accent);
}

.imgGroup {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.imgGroupItem {
    border-radius: 0.75rem;
    border: 2px solid var(--color-primary);
    cursor: pointer;
    transition: transform ease-in-out 0.3s;
}
.imgGroupItem:hover {
    transform: scale(1.1);
}
.imgGroupItem.active {
    border-color: var(--color-accent);
}
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerBtn {
    background-color: var(--color-primary);
    width: 6rem;
    height: 2rem;
    border-radius: 0.75rem;
    border: 1px solid var(--color-accent);
    transition: all ease-in-out 0.3s;
}

.footerBtn:hover {
    background-color: var(--color-primary-variant);
}
