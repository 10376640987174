@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply flex flex-col items-center;
    cursor: default;
}

#root {
    @apply w-full max-w-screen-2xl;
}

input {
    @apply p-4 outline-none border border-gray-300 my-1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* CSS */
:root {
    /* App Colors */
    --color-primary: var(--color-beige);
    --color-primary-variant: var(--color-soft-beige);

    --color-accent: var(--color-strong-beige);
    --color-accent-variant: var(--color-blue);
    --color-text: var(--color-black);
    --color-text-secondary: var(--color-gray);

    --color-text-secondary: var(--color-gray);

    --color-background: var(--color-white);
    --side-font-size: 0.825rem;
    /* Colors */
    --color-white: #ffffff;
    --color-darker-white: RGB(248, 249, 249);
    --color-black: #303030;
    --color-sky-blue: RGB(95, 173, 251);
    --color-blue: RGB(15, 112, 230);
    --color-orange: #fd6413;
    --color-gray: #444754;
    --color-bold-gray: #343540;
    --color-cloud: #c5c6d0;
    --color-purple: #ab68ff;
    --color-beige: #ecc97e;
    --color-sort-bege: #f5e5c1;
    --color-strong-beige: #6e634a;
    --color-gray: RGB(118, 128, 137);

    /* Sider Bar  */
    --side-bar-width: 270px;
    --side-bar-half-width: 135px;
    /* Background */
    --home-bg-gradient: linear-gradient(200deg, rgba(18, 35, 64, 1) 82%, rgba(5, 10, 19, 1) 100%);
}
* {
    box-sizing: border-box;
}

h1,
h2,
h3,
p,
ul {
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
}

a,
Link {
    text-decoration: none;
    color: var(--color-text);
}

button {
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
}

button:focus {
    outline: 1px solid transparent;
}

@font-face {
    font-family: "yeongdeok-sea";
    src: url("./fonts/Yeongdeok-Sea.ttf");
}
