.menu__name {
    all: unset;
    font-size: 1.2rem;
}

.image {
    height: 5rem;
}

.navigation {
    background-color: white;
    opacity: 0.9;
    backdrop-filter: blur(20px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    width: 100%;
    z-index: 50;
}

.navigation__after {
    margin: 0 auto;
    width: 100%;
    padding: 0 1.5rem;
}

/* For the first <div> */
.navigation__logo {
    flex: none;
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

/* For the first <div> on extra-large screens */
@media (min-width: 1280px) {
    .navigation__logo {
        margin-right: 2rem;
    }
}

/* For the second <ul> */
.navigation__menu {
    all: unset;
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 1rem;
    margin-top: 0;
    margin-bottom: 0;
}

/* For the second <ul> on extra-large screens */
@media (min-width: 1280px) {
    .navigation__ul {
        display: flex;
        gap: 2rem;
    }
}

/* For the second <ul> */
.navigation__sign {
    all: unset;
    display: none;
    align-items: center;
    gap: 1rem;
    margin-top: 0;
    margin-bottom: 0;
}

/* For the second <ul> on extra-large screens */
@media (min-width: 1280px) {
    .navigation__sign {
        display: flex;
        gap: 2rem;
    }
}
