.notFoundContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f2f2f2;
}

.errorCode {
    font-size: 10rem;
    color: #333;
}

.errorMessage {
    font-size: 2rem;
    color: #666;
}

.errorDescription {
    font-size: 1rem;
    color: #888;
    text-align: center;
    margin: 1rem;
}

.goHomeButton {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--color-primary);
    color: white;
    text-decoration: none;
    border-radius: 0.25rem;
}

.goHomeButton:hover {
    background-color: var(--color-accent);
}
