/* ProgressBar.module.css */
.progressContainer {
    width: 100%;
    height: 30px;
    background-color: var(--color-cloud);
    border-radius: 0.8rem;
    overflow: hidden;
}

.progressBar {
    height: 100%;
    background-color: var(--color-primary);
    transition: width 0.1s ease;
}

.progressText {
    width: 100%;
    text-align: center;
    line-height: 30px;
    color: var(--color-text);
}
