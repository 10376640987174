.container {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}

.container__select_title {
    font-size: 1.3rem;
    margin: auto;
}
.select__container {
    display: flex;
    margin: 1rem 0;
}
.container__select {
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
    display: block;
    width: 10rem;
    margin: auto;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.container__select:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.255);
}
.taskHelperBtn {
    width: 2rem;
    height: 1rem;
}
.taskHelperBtn:hover {
    color: var(--color-primary);
}
.container__input_text {
    display: block;
    width: 90%;
    padding: 0.375rem 0.75rem;
    margin: auto;
    margin-top: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.container__input_text:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.255);
}

.counter_style {
    font-size: 14px;
    height: 1rem;
    color: #888;
    text-align: right;
    margin: 0;
}

.example_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 10rem;
    margin-left: 1rem;
}

.example_container__image {
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
}
.example_container__image:hover {
    transform: scale(0.9);
}

@media screen and (max-width: 768px) {
    .select__container {
        flex-direction: column;
        gap: 4px;
    }
    .container__input_text {
        height: 2rem;
    }
    .counter_style {
        margin-top: 1px;
    }
}
